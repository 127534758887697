import { createApp } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import main from "./views/main.vue"
import App from './App.vue'; // router
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import VueObserveVisibility from 'vue-observe-visibility'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: main
        }
  ]
});

const app = createApp(App).use(router);
app.use(VueObserveVisibility);
app.mount("#app");