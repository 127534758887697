<template>
      <header2/>
      <header>
        <img src="/kuchnia (2).png" class="background">
        <div class="content">
          <h1>LIGHTED</h1>
          <p>Twój dom, nasze światło! Z nami stwórz idealne oświetlenie dla Twojego wnętrza.</p>
          <div class="buttons">
            <a @click="scrollToSection('services')" class="button type--B">
              <div class="button__line"></div>
              <div class="button__line"></div>
              <span class="button__text">Sprawdź ofertę</span>
              <div class="button__drow1"></div>
              <div class="button__drow2"></div>
            </a>
            <a @click="scrollToSection('about')" class="button type--B">
              <div class="button__line"></div>
              <div class="button__line"></div>
              <span class="button__text">O nas</span>
              <div class="button__drow1"></div>
              <div class="button__drow2"></div>
            </a>
          </div>
        </div>
      </header>
  
      <div class="lighted">
      <main>
        <section class="about" ref="about">
          <h1>O nas</h1>
          <p>
            Lighted to miejsce, gdzie znajdziesz wskazówki, porady i inspiracje dotyczące instalowania oświetlenia w Twoim domu. Nasza misja to pomoc Ci w stworzeniu idealnego oświetlenia, które podkreśli urodę Twoich pomieszczeń i stworzy przyjemną atmosferę.
          </p>
        </section>
        <div class="profile-box">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="profile-content text-center">
            <img src="profilowe.jpg" alt="Zdjęcie profilowe" class="profile-image border-radius" />
            <h2 class="mt-3">Dawid Kowalczyk</h2>
            <p class="profile-description">Specjalizuje się w projektowaniu, instalacji i zarządzaniu oświetleniem wszelkiego rodzaju pomieszczeń oraz elementów domów. Bez względu na to, czy szukasz subtelnych akcentów świetlnych, funkcjonalnego oświetlenia czy rozwiązań inteligentnych.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="profile-content text-center">
            <img src="profilowe_Alan.jpg" alt="Zdjęcie profilowe" class="profile-image border-radius" />
            <h2 class="mt-3">Alan Zieliński</h2>
            <p class="profile-description">Jestem doświadczonym Full Stack Developerem z pasją do tworzenia innowacyjnych aplikacji internetowych. Posiadam szeroką wiedzę zarówno w obszarze frontendu, jak i backendu, co umożliwia mi kompleksowe podejście do projektowania aplikacji webowych.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
        <section class="services" ref="services">
          <h1>Nasze Usługi</h1>
          <h3>Oświetlenie LED:</h3>
          <p>Projektujemy, instalujemy oraz modernizujemy systemy oświetlenia LED dla różnorodnych pomieszczeń mieszkalnych, komercyjnych oraz przestrzeni publicznych. Nasze podejście opiera się na indywidualnym podejściu do projektowania, które dopasowuje oświetlenie LED do potrzeb klienta, zapewniając efektywność energetyczną oraz tworząc odpowiednią atmosferę w pomieszczeniach.</p>

          <h3>Programowanie Aplikacji:</h3>
            <p>Oferujemy usługi programowania aplikacji, zapewniając profesjonalne podejście do tworzenia aplikacji dostosowanych do potrzeb klientów. Nasz zespół programistów posiada doświadczenie w budowaniu aplikacji dostępnych na różnych platformach, zapewniając innowacyjne i wydajne rozwiązania.</p>
          <a @click="scrollToSection('contact')" class="button type--B">
              <div class="button__line"></div>
              <div class="button__line"></div>
              <span class="button__text">Skontaktuj się z nami</span>
              <!-- <div class="button__drow1"></div> -->
              <!-- <div class="button__drow2"></div> -->
            </a>
        </section>
        <h1 class="text-left my-4 text-margin">Opinie</h1>
        <section class="opinions">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="profile-content-opinion text-center">
                  <img src="profilowe_Krystyna.jpg" alt="Zdjęcie profilowe" class="profile-image-opinion" />
                  <div class="profile-content-opinion-details">
                    <h3 class="mt-3">Krystyna</h3>
                    <p class="profile-description">Znają się na tym!</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="profile-content-opinion text-center">
                  <img src="profilowe_Grzesiu.jpg" alt="Zdjęcie profilowe" class="profile-image-opinion" />
                  <div class="profile-content-opinion-details">
                    <h3 class="mt-3">Grzegorz</h3>
                    <p class="profile-description">Zrobili mi choinke z chałupy! super efekt!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <stats/>
  
        <section class="contact" ref="contact">
          <h2>Kontakt</h2>
          <p>
            Jeśli potrzebujesz porady w kwestii oświetlenia domowego lub chcesz umówić się na konsultację, skontaktuj się z nami!
          </p>
          <form>
            <p v-if="this.error" class="error">{{ this.error }}</p>
            <input type="text" id="id" placeholder="Imię i nazwisko:" v-model="name" required>
            <input type="email" id="email" placeholder="Twój adres email:" cols="100" v-model="email" required>
            <textarea id="tresc" placeholder="Treść:" cols="100" ref="trescTextarea" v-model="content" @input="autoExpand" required></textarea>
            <a @click="submitForm" class="button type--B">
              <div class="button__line"></div>
              <div class="button__line"></div>
              <span class="button__text">Wyślij</span>
              <!-- <div class="button__drow1"></div>
              <div class="button__drow2"></div> -->
            </a>
          </form>
        </section>
      </main>
      <!-- <section class="game">
      Możliwość gry w kółko i krzyżyk: <button type="submit" @click=" redirectToAnotherDomain">Graj!</button>
      </section> -->
      <footer>
        <p>&copy; 2023 Lighted - Twój dom, nasze światło</p>
        <div class="socials wrapper" >
          <div class="icon instagram" @click="redirectToAnotherDomain(2)">
            <span class="tooltip">Instagram</span>
            <span class="icon-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
              </svg>
          </span>
        </div>
          <div class="icon github" @click="redirectToAnotherDomain(3)">
            <span class="tooltip">Github</span>
            <span class="icon-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
              <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
              </svg>
            </span>
          </div>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  import header from "../components/header.vue"
  import stats from "../components/stats.vue"

  export default {
    components: {
      "header2": header,
      "stats": stats
    },
    name: 'Lighted',
    data() {
      return {
        emailContent: '',
        email: "",
        name: "",
        content: "",
        error: ""
      };
    },
    methods: {
      scrollToSection(sectionName) {
        const section = this.$refs[sectionName];
        section.scrollIntoView({ behavior: 'smooth' });
      },
      redirectToAnotherDomain(type) {
        if (type == 1) window.location.href = 'http://lukasz.lighted.pl';
        else if (type == 2) window.location.href = 'https://www.instagram.com/sztywnyy04/';
        else if (type == 3) window.location.href = 'https://github.com/Zi0lojsDEV';
      },
      submitForm() {
        // Tutaj wysyłasz dane do serwera
        const payload = {
          name: this.name,
          email: this.email,
          message: this.content,
          // inne dane, jeśli są potrzebne
        };

        // Wykonaj zapytanie POST do serwera
        fetch('http://api.lighted.pl/api/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Sukces:', data);
          // Opcjonalnie, czyść formularz
          if(data.success) {
            this.name = '';
            this.email = '';
            this.content = '';
          }
          this.error = data.content;
        })
        .catch((error) => {
          console.error('Błąd:', error);
          this.error = "Error!";
        });
      },
      autoExpand() {
      //   const textarea = this.$refs.emailTextarea;
      //   textarea.style.transition = 'none';
      //   textarea.style.height = 'auto';
      //   setTimeout(() => {
      //     textarea.style.transition = "height 1s ease-out 1s";
      //   }, 500);
      // },
      const textarea = this.$refs.trescTextarea;
      textarea.style.transition = 'height 0.2s ease-out';
      textarea.style.height = 'auto'; // Ustawienie wysokości na auto, aby obliczyć scrollHeight
      const height = textarea.scrollHeight + 'px'; // Pobranie nowej wysokości
      textarea.style.height = height; // Ustawienie nowej wysokości
      },
      
    }
  };
  </script>
  <style scoped src="../assets/socials.css"/>
  <style scoped src="../assets/buttons.css"/>
  <style scoped src="../assets/main.css"/>