<template>
    <section class="stats">
        <div class="stats">
            <div v-observe-visibility="visibilityChanged" class="stat">
                <span class="counter">{{ this.counter1 }}</span>
                <span class="title">Specjalistów</span>
            </div>
            <div v-observe-visibility="visibilityChanged" class="stat">
                <span class="counter">{{ this.counter2 }}</span>
                <span class="title">Lat doświadczenia</span>
            </div>
            <div v-observe-visibility="visibilityChanged" class="stat">
                <span class="counter">{{ this.counter3 }}</span>
                <span class="title">Zadowolonych klientów</span>
            </div>
            <div class="stat">
                <span class="counter">0</span>
                <span class="title">Zdobytych nagród</span>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                interval: null,
                targetNumber1: 2, // Twój docelowy numer
                targetNumber2: 4, // Twój docelowy numer
                targetNumber3: 2, // Twój docelowy numer
                counter1: 0,
                counter2: 0,
                counter3: 0,
            }
        },
        mounted() {
        this.startCounter1();
        this.startCounter2();
        this.startCounter3();
        },
        methods: {
            startCounter1() {
                const increment = Math.ceil(this.targetNumber1 / 100); // Określenie, jak szybko licznik ma rosnąć
                const intervalTime = 300; // Interwał w milisekundach
                const interval = setInterval(() => {
                if (this.counter1 + increment >= this.targetNumber1) {
                clearInterval(interval);
                this.counter1 = this.targetNumber1;
                } else {
                this.counter1 += increment;
            }
            }, intervalTime)
            },

            startCounter2() {
                const increment = Math.ceil(this.targetNumber2 / 100); // Określenie, jak szybko licznik ma rosnąć
                const intervalTime = 300; // Interwał w milisekundach
                const interval = setInterval(() => {
                if (this.counter2 + increment >= this.targetNumber2) {
                clearInterval(interval);
                this.counter2 = this.targetNumber2;
                } else {
                this.counter2 += increment;
            }
            }, intervalTime)
            },

            startCounter3() {
                const increment = Math.ceil(this.targetNumber3 / 100); // Określenie, jak szybko licznik ma rosnąć
                const intervalTime = 300; // Interwał w milisekundach
                const interval = setInterval(() => {
                if (this.counter3 + increment >= this.targetNumber3) {
                clearInterval(interval);
                this.counter3 = this.targetNumber3;
                } else {
                this.counter3 += increment;
            }
            }, intervalTime)
            },

            onIntersect(entries, observer) {
                entries.forEach(entry => {
                if (entry.isIntersecting) {
                // Wywołanie funkcji, gdy obiekt jest widoczny na ekranie
                this.functionToCall();
                // Możesz również zatrzymać obserwację, jeśli to konieczne
                observer.unobserve(entry.target);
                }
            });
            },
    functionToCall() {
      // Tutaj umieść logikę lub funkcję, którą chcesz wywołać
      console.log('Obiekt jest widoczny na ekranie!');
    }
    }
        }

</script>

<style>
section.stats {
    margin-top: 100px;
    display: flex;
    width: 100%;
}

div.stats {
    display: flex;
    grid-gap: 25px;
    margin: auto;
    font-family: 'Righteous', cursive;
    font-size: 40px; 
    font-weight: normal; 
    line-height: 40px; 
    text-transform: uppercase; 
    text-shadow: 2px 2px 0 #000,;
    text-align: center;
}

div.stat {
    /* width: 300px; */
    height: 100px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    text-align: center;
}

span {
    font-size: 24px;
}

span.title {
    font-size: 18px;
}
</style>
