<template>
    <div class="header" ref="header">
        <img @click="scrollToSection('header')" src="/lighted.png" alt="">
    </div>
</template>
<style scoped>
    .header {
        position: fixed;
        width: 100%;
        height: 100px;
        text-align: left;
    }

    img {
        border-radius: 20px;
        height: auto;
        width: 120px;
        margin-left: 20px;
        margin-top: 20px;
        transition: all 0.2s;
    }

    img:hover {
        cursor: pointer;
        /* background-color: ; */
        transform: scale(105%);
        
    }

</style>

<script>
    export default {
        methods: {
            scrollToSection() {
                window.scrollTo({ top: 0, behavior: "smooth" });
            },
        }
    }
</script>